import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"
import Terms from "../components/sections/terms"

const title = "Terms & Services - Booking Bucket"

const meta = []

const TermsPage = () => (
  <Layout>
    <Seo title={title} meta={meta} />
    <Terms />
  </Layout>
)

export default TermsPage
