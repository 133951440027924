import React from "react"
import styled from "styled-components"
import { ReactComponent as BackImage } from "../../images/svgs/privacy_terms/Back.svg"
import { Link } from "gatsby"

import { SectionContainer, Section } from "../global"

const PrivacyPolicy = () => (
  <StyledSection>
    <SectionContainer>
      <ButtonWrapper>
        <Link to="/">
          <BackButton>
            {" "}
            <BackImage />{" "}
          </BackButton>
        </Link>
      </ButtonWrapper>
      <HeaderTextWrapper>
        <h1>Terms and Services</h1>
      </HeaderTextWrapper>
      <BodyTextWrapper>
        <h6>Revised on 06/25/2021</h6>

        <h5>Use and Service</h5>

        <p>
          This website (herein referred to as &ldquo;Site&rdquo;) services are
          bound to the following terms and conditions. If you visit, register,
          participate or shop at this Site, you accept these terms and
          conditions. Please read them carefully. These Terms of Use
          (&ldquo;TOU&rdquo;) govern your use of this site and are by and
          between Booking Bucket, LLC (referred to herein as &ldquo;Booking
          Bucket&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, or
          &ldquo;our&rdquo;) and you, the user, on behalf of yourself and the
          user, customer or supplier for which you are visiting this Site
          (herein referred to as &ldquo;you&rdquo; or &ldquo;your&rdquo;). By
          using and accepting the services or functions offered in or by this
          Site you waive any right to claim ambiguity or error in these TOU. If
          you do not agree to each and all of these terms and conditions, please
          do not use this Site and leave the Site immediately.
        </p>

        <p>
          The Site is not intended for the use of children under 18 and no such
          person is authorized to use it. By using the Site, you are
          representing that you are at least 18 years old. You also represent,
          by accessing or using the Site, that you are of legal age to enter
          into legal agreements.
        </p>

        <h5>Privacy Policy</h5>

        <p>
          The personal information you submit on the Site is governed by the
          Booking Bucket Privacy Policy, which is incorporated herein by
          reference. Your acceptance of these terms and conditions constitutes
          your acceptance of the Booking Bucket Privacy Policy. If you object to
          your personal information being used as described in the Booking
          Bucket Privacy Policy, please do not submit information to, use or
          register to use the Site.
        </p>

        <h5>Registration</h5>

        <p>
          Access to portions of the Site is limited to registered customers to
          whom we have issued a user identi&#64257;cation and password. If you
          have been issued a user identi&#64257;cation and password, you
          represent to us that all information you provide to us in connection
          with your registration is accurate and up-to-date. You also agree to
          maintain and promptly update your registration information to keep it
          accurate and updated. If you have provided or provide any registration
          information that is untrue, inaccurate, not current or incomplete or
          otherwise fail to comply with any of these terms of use, or we have
          reasonable grounds to suspect that such information is untrue,
          inaccurate, not current or incomplete or that you have otherwise
          failed to comply with any of these terms of use, we have the right,
          without prior notice to you, to suspend or terminate your account and
          refuse any and all current or future use of all or any portion of the
          Site.
        </p>

        <h5>Customer&rsquo;s Responsibilities</h5>

        <p>
          You (i) must keep your passwords secure and con&#64257;dential, (ii)
          are solely responsible for all activity in your account, (iii) must
          use commercially reasonable efforts to prevent unauthorized access to
          your account, and notify us promptly of any unauthorized access, and
          (iv) may use the Site and the services provided by us only in
          accordance with these terms of use and applicable law.
        </p>

        <h5>Changes to Site and Terms of Use</h5>

        <p>
          We reserve the right, at our sole and absolute discretion, to change,
          modify, add, or remove portions of these terms at any time without
          notice and, unless otherwise indicated, such changes will become
          effective immediately; therefore, please check these terms
          periodically for changes. Your continued use of the Site following the
          posting of changes to these TOU will mean you accept those changes.
          This Site may include technical or other inaccuracies. Changes are
          made periodically to the information herein. However, Booking Bucket
          makes no guarantee or commitment to update materials on this site.
        </p>

        <h5>Third-Party Websites</h5>

        <p>
          Our Site may provide links to third-party websites, which are not
          under the control of Booking Bucket. Booking Bucket makes no
          representations about third-party websites. When you access a
          non-Booking Bucket website, you do so at your own risk. Booking Bucket
          is not responsible for the reliability of any data, opinions, advice,
          or statements made on third-party sites. Booking Bucket provides these
          links merely as a convenience for you. The inclusion of such links
          does not imply that Booking Bucket endorses, recommends, or accepts
          any responsibility for the content of such sites.
        </p>

        <h5>Trademarks and Copyrights</h5>

        <p>
          Unless otherwise noted, product names, designs, logos, titles, text,
          images, domain names, audio and video of/within this Site are the
          trademarks, service marks, trade names, copyrights or other property
          (herein referred to as &ldquo;Intellectual Property&rdquo;) of Booking
          Bucket. All other unregistered and registered trademarks are the
          property of their respective owners and are hereby acknowledged.
          Nothing contained on the Site should be construed as granting, by
          implication, estoppel, or otherwise, license or right to use any of
          Booking Bucket&rsquo;s Intellectual Property displayed on the Site
          without the express written permission of Booking Bucket.
        </p>

        <p>
          Four illustrations on the website are the property of Pablo Stanley
          (No Setup, Mobile First, Custom to you, We are excited to have you).
          Icons were made by Becris and Freepik from www.flaticon.com.
        </p>

        <p>
          Booking Bucket respects the intellectual property rights of third
          parties. In the event that you have a good faith belief that your
          copyrights have been violated by the use or display of certain content
          within the Platform, it is our policy to investigate and promptly
          undertake efforts to resolve the issue. To notify us regarding an
          alleged copyright violation, you must provide us with all of the
          following information: (i) a physical or electronic signature of a
          person authorized to act on behalf of the owner of the exclusive right
          that is allegedly infringed; (ii) identification of the copyrighted
          work(s) claimed to have been infringed, and information reasonably
          sufficient to permit us to locate the material; (iii) information
          reasonably sufficient to permit us to contact you, such as an address,
          telephone number, and if available, an electronic mail address at
          which you may be contacted; (iv) a statement that you have a good
          faith belief that use of the material in the manner complained of is
          not authorized by the copyright owner, its agent, or the law; and (v)
          a statement that the information in the notification is accurate, and
          under penalty of perjury, that you are authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed. For this
          notification to be effective, you must provide it to Booking
          Bucket&rsquo;s designated agent at:
          <br />
          Booking Bucket, LLC
        </p>

        <h5>WARRANTY &amp; CONDITIONS</h5>

        <p>
          EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN AN AGREEMENT BETWEEN YOU AND
          BOOKING BUCKET, ALL INFORMATION ON THIS SITE IS PROVIDED &ldquo;AS
          IS&rdquo; WITHOUT WARRANTY OR CONDITIONS OF ANY KIND, EITHER EXPRESS
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT.
        </p>

        <h5>Site Availability</h5>

        <p>
          Booking Bucket is not liable for costs or damage if for any reason,
          any portion of the Site, is not capable of functioning as intended,
          including infection by computer virus, bugs, tampering, unauthorized
          intervention, fraud, technical failures, or any other causes beyond
          the reasonable control of Booking Bucket. Booking Bucket does not
          warrant the site to be uninterrupted or error-free at all times.
        </p>

        <h5>Site Updates &amp; Integrity</h5>

        <p>
          While Booking Bucket makes every effort to provide accurate and secure
          information, we do not guarantee the information. Further, Booking
          Bucket is not responsible or liable for incorrect or inaccurate
          information caused by the users or by any of the equipment or
          programming associated with or utilized in the Site or by any
          technical or human error which may occur in the processing of any
          information related to the Site. Booking Bucket does not guarantee the
          security of information that is transmitted, cached, downloaded,
          stored and/or otherwise replicated outside of the secure environment
          of this Site.
        </p>

        <h5>Communications with this Site</h5>

        <p>
          You are prohibited from posting or transmitting any unlawful,
          libellous, scandalous, defamatory, inflammatory, obscene,
          pornographic, profane, or threatening content or any content that
          could constitute or encourage conduct that would be considered a
          criminal offence, give rise to civil liability, or otherwise violate
          any law. Booking Bucket will fully cooperate with any law enforcement
          authorities or court order requesting or directing Booking Bucket to
          disclose the identity of or help identify or locate anyone posting any
          such information or materials.
        </p>

        <h5>Termination of Use</h5>

        <p>
          Booking Bucket reserves the right and sole discretion (but not the
          obligation) to prohibit you and any member, buyer or supplier from
          using the Site, and to cancel, terminate, modify or suspend the Site
          or any portion thereof and void such information in case of corrupt or
          affected administration, security issues, fairness, integrity, or any
          violation of these terms and conditions. Booking Bucket is held
          harmless in such an event.
        </p>

        <h5>DISCLAIMER</h5>

        <p>
          BOOKING BUCKET ASSUMES NO RESPONSIBILITY FOR ERRORS OR OMISSIONS IN
          THE INFORMATION OR SOFTWARE OR OTHER DOCUMENTS WHICH ARE REFERENCED BY
          OR LINKED TO THIS WEB SITE. IN NO EVENT SHALL BOOKING BUCKET BE LIABLE
          FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY
          KIND, OR ANY DAMAGES WHATSOEVER (INCLUDING WITHOUT LIMITATION, THOSE
          RESULTING FROM: (1) RELIANCE ON THE MATERIALS PRESENTED, (2) COSTS OF
          REPLACEMENT GOODS, (3) LOSS OF USE, DATA OR PROFITS, (4) DELAYS OR
          BUSINESS INTERRUPTIONS, (5) AND ANY THEORY OF LIABILITY, ARISING OUT
          OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION ON THIS
          WEBSITE WHETHER OR NOT BOOKING BUCKET HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </p>

        <h5>Governing Law</h5>

        <p>
          You agree that all matters relating to your access to or use of this
          website shall be governed by the laws of the Province of Ontario,
          Canada, without giving effect to applicable principles of conflicts of
          law to the extent that the application of the laws of another
          jurisdiction would be required thereby. In case of any dispute related
          to these TOU, the parties agree to submit to personal jurisdiction in
          the Province of Ontario, Canada. Furthermore, the parties hereby
          irrevocably and unconditionally submit to the exclusive jurisdiction
          of any court of the Province of Ontario, Canada or any federal court
          sitting in the Province of Ontario, Canada for purposes of any suit,
          action or other proceeding arising out of these TOU. THE PARTIES
          HEREBY IRREVOCABLY WAIVE ANY AND ALL RIGHTS TO A TRIAL BY JURY IN ANY
          ACTION, SUIT OR OTHER PROCEEDING ARISING OUT OF OR RELATING TO THE
          TERMS, OBLIGATIONS AND/OR PERFORMANCE OF THESE TOU. Booking Bucket
          makes no representation that information on this Site is appropriate
          for your use. Those who access this Site do so on their own initiative
          and are responsible for compliance with all applicable law.
          <br />
        </p>
      </BodyTextWrapper>
    </SectionContainer>
  </StyledSection>
)

export default PrivacyPolicy

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.white};
`

const HeaderTextWrapper = styled.div`
  margin: 0;
  text-align: left;
  width: 100%;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    ${props => props.theme.fontSize.larger};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.black};
    line-height: 65px;
  }
`

const BodyTextWrapper = styled.div`
  h4 {
    color: ${props => props.theme.color.black};
    font-weight: ${props => props.theme.fontWeight.semibold};
    line-height: 43px;
  }
  h5 {
  }
  h6 {
    margin-top: 0px;
    font-style: italic;
    font-size: 24px;
    font-weight: ${props => props.theme.fontWeight.normal};
  }

  h7 {
    font-size: 20px;
    font-weight: ${props => props.theme.fontWeight.normal};
    color: ${props => props.theme.color.black};
    text-decoration: underline;
  }

  p {
    color: ${props => props.theme.color.black};
    ${props => props.theme.fontSize.xsmall};
    line-height: 21px;
  }

  ul {
    line-height: 21px;
  }
`
const ButtonWrapper = styled.div`
  width: 100%;
  text-align: left;
`
const BackButton = styled.button`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.xlarge};
  color: ${props => props.theme.color.black};
  letter-spacing: 1px;
  height: 10px;
  display: block;
  margin-bottom: 32px;

  outline: 0px;

  &:active {
    filter: drop-shadow(0px 0px 1px #00000066);
    transform: translateY(3px);
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
